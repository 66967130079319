import React from "react";
import SEO from "../../../commons/seo";
import styles from "./privacy.module.scss";
import BlogLayout from "../../blog-layout/BlogLayout";
import BlogHeader from "../../blog-header/BlogHeader";
import cx from "classnames";
import { Seo } from "../../../../models/seo.model";
import PrivacyComponent from "../../../commons/privacy";
import { RouteLinks } from "../../../../utils/route-links";

interface Props {
  seo: Seo;
}

const Privacy: React.FC<Props> = (props: Props) => {
  const { metaTitle, metaDescription, metaKeywordList } = props.seo.frontmatter;

  return (
    <BlogLayout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywordList}
        pageUrl={RouteLinks.privacy}
      />
      <BlogHeader>
        <h1 className={cx("blog-d2", styles.heading)}>Privacy</h1>
      </BlogHeader>
      <PrivacyComponent />
    </BlogLayout>
  );
};

export default Privacy;
