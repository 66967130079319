import React from "react";
import Privacy1 from "../components/v1/pages/privacy";
import Privacy2 from "../components/v2/pages/privacy";
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  {
    seo: markdownRemark(
      fields: { category: { eq: "seo" }, relativePath: { eq: "seo__privacy" } }
    ) {
      frontmatter {
        metaTitle
        metaDescription
        metaKeywordList
      }
    }
  }
`;

const Privacy = () => {
  const queryResult = useStaticQuery(query);

  if (process.env.GATSBY_VERSION === "v1") {
    return <Privacy1 seo={queryResult.seo} />;
  } else {
    return <Privacy2 seo={queryResult.seo} />;
  }
};

export default Privacy;
